<template>
  <a-card>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="创建优惠劵"
      sub-title=""
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['name', {rules: [{ required: true, message: '请输入' }]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="满足金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number v-decorator="['limit', {rules: [{required: true, message: '请输入'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="优惠类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                v-decorator="['category', {rules: [{ required: true, message: '请输入' }]}]"
                allowClear
                @change="categoryChange"
                placeholder="请选择">
                <a-select-option v-for="(val, key) in this.$Dictionaries.coupon_category" :value="key" :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="优惠金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number v-decorator="['value', {rules: [{required: true, message: '请输入'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="商品类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                v-decorator="[
                  'goods_type',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.GoodsType">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="截至时间类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select style="width: 30%" v-decorator="['duration_type',{initialValue: '1', rules: [{ required: true, message: '请选择' }]}]" placeholder="选择" @change="durationTypeChange">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.duration_type">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="总量" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['max_count', {rules: [{required: true, message: '请输入'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="this.duration_type === 1">
            <a-form-item label="天数" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number style="width: 30%" v-decorator="['days', {rules: [{required: this.duration_type === 1, message: '请输入'}]}]" />
              <span style="margin-left: 10px;">领取优惠劵后开始计算有效天数</span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="this.duration_type === 2">
            <a-form-item label="截至日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-date-picker
                v-decorator="['end_date', {rules: [{required: this.duration_type === 2, message: '必填项，请填写信息'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="截至日期"
                value-format="yyyy-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="单人限量" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['max_user_count', {rules: [{required: true, message: '请输入'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="前台显示" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-switch v-decorator="['is_show', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="新用户专用" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-switch v-decorator="['is_new', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-switch v-decorator="['is_enable', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="this.category === '2'">
            <a-form-item label="优惠劵对应商品分类" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="false">
              <a-tag v-for="tag in categoryChooseList" :key="tag" type="primary" closable @close="handleCategoryClose(tag)">
                {{ tag }}
              </a-tag>
              <a-cascader
                :options="categoryList"
                placeholder="请选择"
                @change="handleCategoryChange"
                :field-names="{ label: 'name', value: 'code', children: 'children' }" />
            </a-form-item>
            <a-form-item label="优惠劵对应商品分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['category_list', {rules: [{required: this.category === '2', message: '请输入'}]}]" />
              <span>用英文逗号分割商品分类编号。例如：xxxxxx</span>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="this.category === '3'">
            <a-form-item label="优惠劵对应商品" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['goods_list', {rules: [{required: this.category === '3', message: '请输入'}]}]" />
              <span>用英文逗号分割商品条形码。例如：xxxx,xx,xxxxxx</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <div>
      <a-row>
        <a-col :span="24"></a-col>
        <a-col :span="4"></a-col>
        <a-col :span="6" style="text-align: center">
          <a-button @click="handleCancel">取消</a-button>
        </a-col>
        <a-col :span="6" style="text-align: center">
          <a-button type="primary" @click="handleCreate" :loading="loading">创建</a-button>
        </a-col>
        <a-col :span="8"></a-col>
      </a-row>
    </div>
  </a-card>
</template>
<script>
import pick from 'lodash.pick'

import { putCoupon, createCoupon } from '@/api/applet_shop_coupon'
import { goods_category_list } from '@/api/c_wms_goods'

const fields = ['warehouse_code', 'phone', 'name', 'province', 'city', 'county', 'address', 'longitude', 'latitude', 'is_used']
export default {
  name: 'DeliverAddressForm',
  components: {},
  props: {
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      loading: false,
      category: undefined,
      duration_type: 1,
      dataSource_wms_warehouse: [],
      categoryList: [],
      categoryChooseList: [],
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
      this.goodsCategory()
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    },
    durationTypeChange (value) {
      console.log(typeof value)
      this.duration_type = Number(value)
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleCreate () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl && this.mdl.id) {
            this.loading = true
            putCoupon(values, this.mdl.id).then((res) => {
              this.loading = false
              this.handleCancel()
            }).finally(() => {
              this.loading = false
            })
          } else {
            console.log('提交的数据----', values)
            if (this.category === '3') {
              values.goods_list = values.goods_list.split(',')
            } else if (this.category === '2') {
              values.category_list = values.category_list.split(',')
              const filter_category_list = values.category_list.filter(item => {
                return item.length === 6
              })
              if (filter_category_list.length !== values.category_list.length) {
                this.$message.warning('优惠券对应三级分类')
                return
              }
            }
            values.category = Number(values.category)
            values.limit = Math.round(values.limit * 100)
            values.value = Math.round(values.value * 100)
            this.loading = true
            createCoupon(values).then((res) => {
              this.loading = false
              this.handleCancel()
            }).finally(() => {
              this.loading = false
            })
          }
        } else {
          this.loading = false
        }
      })
    },
    goodsCategory () {
      goods_category_list().then(res => {
        const entries = res.data.entries
        const level1 = []
        const level2 = []
        const level3 = []
        entries.forEach((e, i) => {
          if (e.level === 1) {
            level1.push(e)
          } else if (e.level === 2) {
            level2.push(e)
          } else {
            level3.push(e)
          }
        })

        level1.forEach((e, i) => {
          e.children = []
          level2.forEach((e2, i2) => {
            e2.children = []
            if (e.code === e2.code.substr(0, 2)) {
              e.children.push(e2)
            }
            level3.forEach((e3, i3) => {
              if (e2.code === e3.code.substr(0, 4)) {
                e2.children.push(e3)
              }
            })
          })
        })
        this.categoryList = level1
      })
    },
    categoryChange (value) {
      this.category = value
    },
    handleCategoryChange (value) {
      console.log('handleCategoryChange--', value)
      if (this.categoryChooseList.indexOf(value) === -1) {
        this.categoryChooseList.push(value)
      } else {
        this.$message.warning('已存在')
      }
    },
    handleCategoryClose (tag) {
      const index = this.categoryChooseList.indexOf(tag)
      this.categoryChooseList.splice(index, 1)
    }
  }
}
</script>
<style>
</style>
